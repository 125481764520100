import request from '@/utils/request';
export const postCustomerList = (data: any) => {
  // 公海列表数据
  return request({
    url: 'zqcrm/customerStatusTag/list',
    method: 'POST',
    data
  });
};
export const queryVisitRecordByCode = (data: any): any => {
  // 客户容量列表
  return request({
    url: 'zqcrm/visitRecords/queryVisitRecordByCode',
    method: 'GET',
    params: data
  });
};
export const zqCapacitySet = (data: any): any => {
  // 客户容量列表
  return request({
    url: 'zqcrm/setting/capacitySet/list',
    method: 'GET',
    params: data
  });
};
export const zqCapacitySetEdit = (data: JSON): any => {
  // 客户容量列表更新
  return request({
    url: 'zqcrm/setting/capacitySet/edit',
    method: 'POST',
    data
  });
};
export const addClient = (data: any): any => {
  // 推荐客户接口
  return request({
    url: 'zqcrm/examine/customerExamine/add',
    method: 'POST',
    data
  });
};
export const transfer = (data: any) => {
  // 转移客户
  return request({
    url: 'zqcrm/customer/transfer',
    method: 'POST',
    data
  });
};
export const receive = (data: any) => {
  // 公海领取接口
  return request({
    url: 'zqcrm/customer/receive',
    method: 'POST',
    params: data
  });
};
export const importCustomerBase = (data: any) => {
  // 批量导入
  return request({
    url: 'zqcrm/customer/importCustomerBase ',
    method: 'POST',
    data
  });
};
export const getSellerList = (data: any): any => {
  // 获取推荐人列表
  return request({
    url: 'zqcrm/customer/getSellerList',
    method: 'POST',
    data
  });
};
export const getQccInfo = (data: any): any => {
  // 获取推荐人列表
  return request({
    url: '/zqcrm/admin/zqCustomer/getQccInfo',
    method: 'GET',
    params: data
  });
};
export const getListCheck = (data: any): any => {
  // 客户审批列表
  return request({
    url: 'zqcrm/examine/customerExamine/list',
    method: 'GET',
    params: data
  });
};
export const refuse = (id: any): any => {
  // 客户审批拒绝
  return request({
    url: `zqcrm/examine/customerExamine/refuse/${id}`,
    method: 'GET'
  });
};
export const agree = (id: any): any => {
  // 客户审批同意
  return request({
    url: `zqcrm/examine/customerExamine/agree/${id}`,
    method: 'GET'
  });
};
export const industryList = () => {
  // 客户行业列表
  return request({
    url: 'zqcrm/industry/list',
    method: 'GET'
  });
};

export const importLog = (data: any) => {
  // 批量导入列表
  return request({
    url: 'zqcrm/customer/importLog',
    method: 'POST',
    data
  });
};
export const zqCustomerAdd = (data: any) => {
  // 公海模板下载
  return request({
    url: 'zqcrm/admin/zqCustomer/add',
    method: 'POST',
    data
  });
};
export const getQiChaChaData = (data: any) => {
  // 公海模板下载
  return request({
    url: 'zqcrm/customer/getQiChaChaData',
    method: 'POST',
    data
  });
};

export const exportTemplate = (data: any) => {
  // 公海模板下载
  return request({
    url: 'zqcrm/admin/zqCustomer/exportTemplate',
    method: 'POST',
    data
  });
};
export const getCustomerById = (data: any) => {
  // 获取客户信息  /客户详情
  return request({
    url: 'zqcrm/customer/getCustomerInfoById',
    method: 'POST',
    data
  });
};
export const queryOrderRecord = (data: any) => {
  // 获取客户订单列表
  return request({
    url: 'zqcrm/order/queryOrderRecord',
    method: 'GET',
    params:data
  });
};
export const getFollowStatus = (id: any) => {
  // 写新记录 客户状态
  return request({
    url: `zqcrm/customer/getFollowStatus/${id}`,
    method: 'GET'
  });
};
export const customerAdd = (data: any) => {
  // 储备客户添加客户
  return request({
    url: 'zqcrm/customer/add',
    method: 'POST',
    data
  });
};
export const getOverdueInfo = () => {
  // 储备客户添加客户
  return request({
    url: 'zqcrm/customer/getOverdueInfo',
    method: 'GET'
  });
};
export const filedType = () => {
  // 储备客户添加客户
  return request({
    url: 'zqcrm/customerStatusTag/filedType',
    method: 'POST'
  });
};
export const updateIntellectualHonor = (data: any) => {
  // 编辑 综销 金融 项目
  return request({
    url: 'zqcrm/intellectualHonor/updateIntellectualHonor',
    method: 'POST',
    data
  });
};
export const updateBalanceStatus = (data: any) => {
  // 编辑 综销 资产情况
  return request({
    url: 'zqcrm/balanceStatus/updateBalanceStatus',
    method: 'POST',
    data
  });
};
export const updateBusinessStatus = (data: any) => {
  // 编辑 综销 跟进详情填写
  return request({
    url: 'zqcrm/businessStatus/updateBusinessStatus',
    method: 'POST',
    data
  });
};
export const updateIncomeCredit = (data: any) => {
  // 编辑 金融 收入情况 - 征信情况
  return request({
    url: 'zqcrm/incomeCredit/updateIncomeCredit',
    method: 'POST',
    data
  });
};
export const addCustomerFollow = (data: any) => {
  // 正企 - 写新跟进 提交
  return request({
    url: 'zqcrm/customerFollow/addCustomerFollow',
    method: 'POST',
    data
  });
};
export const getCustomerFollowList = (data: any) => {
  // 跟进记录列表
  return request({
    url: 'zqcrm/customerFollow/getCustomerFollowList',
    method: 'POST',
    data
  });
};
export const getBatchList = (data: any) => {
  // 跟进记录列表
  return request({
    url: 'zqcrm/customer/getBatchList',
    method: 'POST',
    data
  });
};
export const editCustomer = (data: any) => {
  // 跟进记录列表
  return request({
    url: 'zqcrm/customer/editCustomer',
    method: 'POST',
    data
  });
};
export const editCustomerStatusTag = (data: any) => {
  // 客户删除 接口
  return request({
    url: 'zqcrm/customerStatusTag/editCustomerStatusTag',
    method: 'POST',
    data
  });
};
// export const getProduct = (data: any) => {
//   // 客户删除 接口
//   return request({
//     url: 'zqcrm/product/product/getProduct',
//     method: 'GET',
//     params: data,
//   })
// }
export const getProduct = (data: any): any => {
  // 客户容量列表
  return request({
    url: 'zqcrm/product/product/getProduct',
    method: 'GET',
    params: data
  });
};
// 外呼
export const record = (data: any) => {
  return request({
    url: 'zqcrm/notify/call/record',
    method: 'POST',
    data
  });
};
// 通话记录
export const file = (data: any) => {
  return request({
    url: 'zqcrm/notify/call/file',
    method: 'POST',
    data
  });
};
// 企查查
export const qiChaChaConfirm = (data: any): any => {
  return request({
    url: 'zqcrm/customer/qiChaChaConfirm',
    method: 'GET',
    params: data
  });
};
// 印章管理列表接口
export const getContractSealList = (data: any) => {
  return request({
    url: 'zqcrm/contractSeal/getContractSealList',
    method: 'POST',
    data
  });
};
// 新增印章
export const addContractSeal = (data: any) => {
  return request({
    url: 'zqcrm/contractSeal/addContractSeal',
    method: 'POST',
    data
  });
};
// 印章管理删除
export const delContractSealById = (data: any): any => {
  return request({
    url: 'zqcrm/contractSeal/delContractSealById',
    method: 'GET',
    params: data
  });
};

export const getExclusivePerson = (data: any): any => {
  // 批量导入
  return request({
    url: 'zqcrm/customer/getExclusivePerson',
    method: 'POST',
    data
  });
};