<template>
    <div class="drag-ball" draggable="true" @dragstart="dragstart($event)" @dragend="dragend($event)"
        :style="`right:${elRight}px;bottom:${elBottom}px`">
        <div class="imgMore" @click="openData">
            <img v-if="informationOpen == false" src="https://source.fungsong.com/3443441830143vUs0Wc3oPvEh.png" alt="">
            <img v-else src="https://source.fungsong.com/3443615225288ovpJHDxrJBTe.png" alt="">
        </div>
        <div :class="location == 'left' ? 'moreModal-data-left' : 'moreModal-data-rigth'" v-if="isMenuVisible">
            <el-tabs v-model="activeName" @tab-click="handleClick" @tab-change="handleChange">
                <el-tab-pane label="通知公告" name="1">
                    <div class="inform" v-infinite-scroll="notification" style="overflow:auto">
                        <div class="inform-data" v-for="(item, index) in noticeList" :key="index"
                            @click="particulars(item)">
                            <div class="sj-piece" v-if="item.isTop == 1">
                                <img src="https://source.fungsong.com/3443577887430r8hgr0tiuK41.png" alt=""
                                    style="width: 20px;height: 20px;">
                            </div>
                            <!-- <div style="margin-right: 8px;">
                      <img src="asdasd" alt="" style="height: 56px; width: 56px">
                    </div> -->
                            <div class="inform-data-contents">
                                <div class="inform-data-content-title">
                                    <div v-if="item.status == 0" class="title-content-dot">
                                        •
                                    </div>
                                    <div class="title-content">
                                        {{ item.title }}
                                    </div>
                                    <div class="title-time">
                                        {{ item.createTime }}
                                    </div>
                                </div>
                                <div class="inform-data-content-text">
                                    {{ item.content }}
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="待办事项" name="2">
                    <div class="inform" v-infinite-scroll="backlogList" infinite-scroll-distance="0px"
                        style="overflow:auto">
                        <div class="inform-data" v-for="(item, index) in backlogLists" :key="index"
                            @click="goProcess(item)">
                            <div class="sj-piece">
                            </div>
                            <!-- <div style="margin-right: 8px;">
                      <img src="asdasd" alt="" style="height: 56px; width: 56px">
                    </div> -->
                            <div class="inform-data-contents">
                                <div class="inform-data-content-title">
                                    <div v-if="item.status == 0" class="title-content-dot">
                                        •
                                    </div>
                                    <div class="title-content">
                                        【 {{ item.source }} 】 {{ item.title }}
                                    </div>
                                    <div class="title-time">
                                        {{ item.createTime }}
                                    </div>
                                </div>
                                <div class="inform-data-content-text">
                                    {{ item.content }}
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
            <el-dialog :title="eDetils.title" v-model="dialogVisible" width="20%">
                <p>{{ eDetils.content }}</p>
                <div style="display: flex; justify-content: center; margin-top: 20px;">
                    <img style="width: 100px;margin-right: 10px" v-for="(item, index) in eDetils.files" :key="index"
                        :src="item" alt="">
                </div>
                <span slot="footer" class="dialog-footer" v-if="eDetils.status == 0 && eDetils.isConfirm == 1">
                    <el-button type="primary" @click="haveKnown(eDetils.id)">已知悉！确认</el-button>
                </span>
            </el-dialog>
        </div>
    </div>

</template>
<script lang="ts" setup>
import { ref, onMounted, onUnmounted, reactive } from 'vue';
import { getSource, getAppNotifyTaskList, getAppBulletinLogList, getAppBulletinLogRead, postAppBulletinLogGetUnreadCount } from './api';
import { Backlog, Notice, QueryParams, BacklogParams, DialogDetails, SocketCon } from './types';

const startclientX = ref(0); //记录开始的横坐标位置
const startclientY = ref(0); //记录开始的纵坐标位置
const elRight = ref('50'); //定位-初始位置
const elBottom = ref(200); //定位-初始位置
const location = ref('rigth');
const isMenuVisible = ref(false)//铃铛页面展示
const activeName = ref('1');//默认选中的选择框
const informationOpen = ref(false);//悬浮按钮样式
const backlogLists = ref<Backlog[]>([]); //待办列表
const noticeList = ref<Notice[]>([])//通知公告列表
const defaultQuery = {
    pageNum: 1,
    pageSize: 10,
    oldPage: 0,
    bank: true,
};

const defaultBacklog = {
    activeBName: '',
    activeIndex: 0,
    pageNum: 1,
    pageSize: 10,
    oldPage: 0,
    bank: true,
};
const query = reactive<QueryParams>({
    pageNum: 1,
    pageSize: 10,
    oldPage: 0,
    bank: true,
});

const backlog = reactive<BacklogParams>({
    activeBName: '',
    activeIndex: 0,
    pageNum: 1,
    pageSize: 10,
    oldPage: 0,
    bank: true,
});
const socketConent = reactive<SocketCon>({
    webUrl: import.meta.env.VITE_APP_SOCKET_URL,//webSocket地址
    websocket: null, // WebSocket对象
    reconnectInterval: 30000, // 重连间隔时间（毫秒）
    heartbeatInterval: null, // 心跳定时器
})

const dialogVisible = ref(false)// 通知公告弹窗
const eDetils = reactive<DialogDetails>({
    title: '',
    content: '',
    files: [],
    isConfirm: 0,
    status: 0,
    id: '',
});
onMounted(() => {
    // 关闭铃铛的 WebSocket 原因 → 导致页面报错
    setupWebSocket();
    unread();
});

onBeforeUnmount(() => {
    closeWebSocket(); // 在组件卸载前关闭WebSocket连接
})
// 拖拽开始事件
const dragstart = (e: any) => {
    // 记录拖拽元素初始位置
    startclientX.value = e.clientX;
    startclientY.value = e.clientY;
};
// 拖拽完成事件
const dragend = (e: any) => {
    let {
        clientHeight: windowHeight,
        clientWidth: windowWidth
    } = document.documentElement;
    let x = startclientX.value - e.clientX; // 计算偏移量
    let y = startclientY.value - e.clientY;
    if (x <= (windowWidth / 2)) {  //  left 小于等于屏幕一半
        elRight.value = '0';
        location.value = 'rigth'
    } else { //  left 大于屏幕一半
        elRight.value = 'auto';
        location.value = 'left'
    }
    elBottom.value += y;
};

// 时间比较
const temporal = (timeDetails: string) => {
    let now = new Date();
    let year = now.getFullYear(); // 年份（四位数）
    let month = now.getMonth() + 1; // 月份（注意：JavaScript中的月份是从0开始的，所以需要+1）
    let day = now.getDate(); // 日期
    let hours = now.getHours(); // 小时
    let minutes = now.getMinutes(); // 分钟
    let seconds = now.getSeconds(); // 秒
    let startTime = `${now.getFullYear()}-${addLeadingZero(month)}-${addLeadingZero(day)}T${addLeadingZero(hours)}:${addLeadingZero(minutes)}:${addLeadingZero(seconds)}`;
    let endTime = convertToISOFormat(timeDetails)
    return calculateTimeDifference(startTime, endTime)
}
const addLeadingZero = (value: number) => {
    return value < 10 ? '0' + value : value;
}
const convertToISOFormat = (dateString: string) => {
    // 确保输入是字符串格式
    return dateString.replace(' ', 'T');
}
const calculateTimeDifference = (startDateStr: string | number | Date, endDateStr: string | number | Date) => {
    // 将字符串时间转换为Date对象
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    // 获取两个日期的毫秒差
    const diffMilliseconds = Math.abs(endDate.valueOf() - startDate.valueOf());

    // 定义时间单位及其对应的毫秒数（简单估算，月按30天计算）
    const units = [{
        name: '年',
        milliseconds: 365 * 24 * 60 * 60 * 1000
    },
    {
        name: '月',
        milliseconds: 30 * 24 * 60 * 60 * 1000
    }, // 按30天计算
    {
        name: '天',
        milliseconds: 24 * 60 * 60 * 1000
    },
    {
        name: '小时',
        milliseconds: 60 * 60 * 1000
    },
    {
        name: '分钟',
        milliseconds: 60 * 1000
    },
    {
        name: '秒',
        milliseconds: 1000
    }
    ];

    // 计算差异并找到最合适的单位
    for (const unit of units) {
        const count = Math.floor(diffMilliseconds / unit.milliseconds);
        if (count >= 1) {
            if (unit.name === '秒' && count <= 60) {
                return count === 1 ? '刚刚发送' : `${count}秒前`;
            } else {
                return count === 1 ? `1${unit.name}前` : `${count}${unit.name}前`;
            }
        }
    }
    return '刚刚发送';
}
const openData = () => {
    isMenuVisible.value = !isMenuVisible.value
    if (isMenuVisible.value == true) {
        handleChange(activeName.value)
    }
}
const handleChange = (e: any) => {
    backlogLists.value = [] //待办列表
    noticeList.value = [] //通知公告列表
    // 重置 query
    Object.assign(query, defaultQuery);
    // 重置 backlog
    Object.assign(backlog, defaultBacklog);
    activeName.value = e
    if (e == '1') {
        notification()
    } else {
        backlogList()
    }
}
// 请求分类待办列表or 通知公告列表
const handleClick = (tab: any) => {
}
// 请求通知公告列表
const notification = () => {
    let pageNum = query.pageNum
    let pageSize = query.pageSize
    let msgType = 1
    if (query.bank == false) {
        ElMessage({
            message: '已经是全部了',
            type: 'warning',
        })
    } else {
        getAppBulletinLogList(pageSize, pageNum, msgType).then(res => {
            if (res.code == 200) {
                if (res.rows.length != 0) { // 是否有数据
                    res.rows.forEach((ele: { files: any; createTime: any; }) => {
                        ele.files = ele.files.split(',')
                        ele.createTime = temporal(ele.createTime);
                    });
                    noticeList.value = noticeList.value.concat(res.rows)
                    if (res.rows.length < 10) { // 判断是否有下一页
                        query.oldPage = pageNum
                        query.pageNum = pageNum
                        query.bank = false
                    } else { // 存在下一页
                        query.oldPage = pageNum
                        query.pageNum = pageNum + 1
                        query.bank = true
                    }
                } else {
                    let bankList = noticeList.value;
                    if (query.pageNum == 1) {
                        ElMessage({
                            message: '当前没有消息',
                            type: 'warning',
                        })
                    } else {
                        ElMessage({
                            message: '没有更多消息了',
                            type: 'warning',
                        })
                    }
                    query.bank = false
                    query.oldPage = pageNum
                    query.pageNum = pageNum
                    noticeList.value = bankList
                }
            } else {
                ElMessage.error(res.msg)
            }
        })
    }
}
// 通知公告详情
const particulars = (e: any) => {
    eDetils.title = e.title
    eDetils.content = e.content
    eDetils.files = e.files
    eDetils.isConfirm = e.isConfirm
    eDetils.status = e.status
    eDetils.id = e.id
    dialogVisible.value = true
}
// 已知悉
const haveKnown = (e: any) => {
    let id = e
    getAppBulletinLogRead(id).then(res => {
        if (res.code == 200) {
            dialogVisible.value = false
            activeName.value = '1'
            handleChange('1')
        } else {
            ElMessage.error(res.msg)
        }
    })
}

// 请求待办详情列表
const backlogList = () => {
    let pageNum = backlog.pageNum
    let pageSize = backlog.pageSize
    let acceptor = localStorage.getItem('userId');
    // let source = this.backlog.activeBName
    if (backlog.bank == false) {
        ElMessage({
            message: '已经是全部了',
            type: 'warning',
        })
    } else {
        getAppNotifyTaskList(pageSize, pageNum, acceptor).then(res => {
            if (res.code == 200) {
                if (res.rows.length != 0) { // 是否有数据
                    res.rows.forEach((ele: { createTime: any; }) => {
                        // ele.files = ele.files.split(',')
                        ele.createTime = temporal(ele.createTime);
                    });
                    backlogLists.value = backlogLists.value.concat(res.rows)
                    if (res.rows.length < 10) { // 判断是否有下一页
                        backlog.oldPage = pageNum
                        backlog.pageNum = pageNum
                        backlog.bank = false
                    } else { // 存在下一页
                        backlog.oldPage = pageNum
                        backlog.pageNum = pageNum + 1
                        backlog.bank = true
                    }
                } else {
                    let bankList = backlogLists.value;
                    if (backlog.pageNum == 1) {
                        ElMessage({
                            message: '当前没有消息',
                            type: 'warning',
                        })
                    } else {
                        ElMessage({
                            message: '没有更多消息了',
                            type: 'warning',
                        })
                    }
                    backlog.bank = false
                    backlog.oldPage = pageNum
                    backlog.pageNum = pageNum
                    backlogLists.value = bankList
                }
            } else {
                ElMessage.error(res.msg)
            }
        })
    }
}
// 跳转详情
const goProcess = (row: any) => {
    let token = localStorage.getItem('Admin-Token')
    window.open(`${import.meta.env.VITE_APP_PROCESS_DETAIL_URL}?id=` + row.relatedId + "&taskId=" + row.relatedParams + "&taskType=2&token=" + token);
}


// 长链接

const setupWebSocket = () => {
  const token = localStorage.getItem("Admin-Token")
  const clientid = import.meta.env.VITE_APP_CLIENT_ID;
  if (!token || !clientid) {
    console.error('Token or Client ID not found in cookies.');
    return;
  }

  const url = `${socketConent.webUrl}?Authorization=Bearer ${token}&clientid=${clientid}`;

  if (socketConent.websocket) {
    return
    socketConent.websocket.close();
  }

  socketConent.websocket = new WebSocket(url);

  socketConent.websocket.onopen = onWebSocketOpen;
  socketConent.websocket.onmessage = onWebSocketMessage;
  socketConent.websocket.onerror = onWebSocketError;
  socketConent.websocket.onclose = onWebSocketClose;
};

const closeWebSocket = () => {
  if (socketConent.websocket) {
    socketConent.websocket.close();
    socketConent.websocket = null;
  }
};

const onWebSocketOpen = () => {
  startHeartbeat();
  sendMessage(JSON.stringify({ type: 'PING' }));
};

const onWebSocketMessage = (event: MessageEvent<string>) => {
  if (event.data) {
    try {
      const messageData = JSON.parse(event.data);
      if (messageData.type !== 'PING') {
        informationOpen.value = true;
      }
    } catch (error) {
      console.error('Failed to parse WebSocket message:', error);
    }
  }
};

const onWebSocketError = (event: Event) => {
  console.error('WebSocket error:', event);
  // 外呼未建立连接     
  return
  closeWebSocket();
  setTimeout(setupWebSocket, socketConent.reconnectInterval);
};

const onWebSocketClose = (event: CloseEvent) => {
  stopHeartbeat();

  if (event.wasClean) {
    console.log(`Connection closed cleanly, code=${event.code} reason=${event.reason}`);
  } else {
    console.error('Connection died unexpectedly');
  }

  setTimeout(setupWebSocket, socketConent.reconnectInterval);
};

const sendMessage = (message: string) => {
  if (socketConent.websocket && socketConent.websocket.readyState === WebSocket.OPEN) {
    socketConent.websocket.send(message);
  }
};

const startHeartbeat = () => {
  socketConent.heartbeatInterval = setInterval(() => {
    if (socketConent.websocket && socketConent.websocket.readyState === WebSocket.OPEN) {
      sendMessage(JSON.stringify({ type: 'PING' }));
    }
  }, 10000);
};

const stopHeartbeat = () => {
  if (socketConent.heartbeatInterval) {
    clearInterval(socketConent.heartbeatInterval);
    socketConent.heartbeatInterval = null;
  }
};

const unread = () => {
    postAppBulletinLogGetUnreadCount().then(res => {
        if (res.data > 0) {
            informationOpen.value = true
        } else {
            informationOpen.value = false
        }
    })
};

</script>
<style scoped lang="scss">
.drag-ball {
    cursor: pointer;
    position: absolute;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    z-index: 9999;
    background-color: transparent;
}

.imgMore {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    position: relative;
}


.moreModal-data-left {
    width: 400px;
    max-height: 342px;
    border-radius: 4px;
    background: rgba(248, 249, 250, 1);
    box-shadow: 0px 4px 8px rgba(194, 195, 205, 0.25);
    position: absolute;
    left: 10px;
    box-sizing: border-box;
}

.moreModal-data-rigth {
    width: 400px;
    max-height: 342px;
    background: rgba(248, 249, 250, 1);
    box-shadow: 0px 4px 8px rgba(194, 195, 205, 0.25);
    border-radius: 4px;
    position: absolute;
    right: 10px;
    box-sizing: border-box;
    color: #1A1A1A;
}

.moreModal-tabs-name {
    width: 84px;
    border-right: 1px solid var(--温柔灰, rgba(228, 229, 231, 1));
}

.title-name-content {
    width: 84px;
    height: 50px;
    background: rgba(248, 249, 250, 1);
    line-height: 50px;
    font-size: 12px;
    color: var(--真字体黑, rgba(13, 13, 38, 1));
}

.title-name-contentAc {
    width: 84px;
    height: 50px;
    background: rgba(13, 13, 38, 1);
    color: rgba(248, 249, 250, 1);
    line-height: 50px;
    font-size: 12px;
    text-align: left;
}

.name-boxAC {
    padding-left: 14px;
    box-sizing: border-box;
    border-left: 2px solid rgba(248, 249, 250, 1);
}

.name-box {
    box-sizing: border-box;
    border-left: 2px solid rgba(248, 249, 250, 1);
}

.moreModal-tabs-data {
    width: 315px;
    margin-top: 12px;
    margin-bottom: 12px;
}

.inform {
    width: 100%;
    max-height: 263px;
    overflow: auto;
    margin-top: 12px;
    margin-bottom: 12px;
}

.inform-data {
    width: 100%;
    height: 62px;
    display: flex;
    margin-top: 8px;
    border-bottom: 1px solid rgba(113, 128, 150, 0.1);
    padding: 0 16px 8px 16px;
    position: relative;
}

.inform-data-content {
    width: 302px;
    height: 100%;
}

.inform-data-contents {
    width: 362px;
    height: 100%;
}

.inform-data-content-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.inform-data-content-text {
    font-size: 12px;
    width: 100%;
    line-height: 14px;
    /* 设置行高，可选 */
    overflow: hidden;
    /* 隐藏超出的内容 */
    text-overflow: ellipsis;
    /* 超出部分显示为省略号 */
    display: -webkit-box;
    /* 使用Flexbox布局模型，适用于Webkit内核的浏览器 */
    -webkit-line-clamp: 1;
    /* 限制在一个块元素显示的文本的行数 */
    -webkit-box-orient: vertical;
    /* 设置或检索伸缩盒对象的子元素的排列方式 */
    color: rgba(149, 150, 157, 1);
    text-align: left;
    font-size: 12px;
    margin-top: 4px;
}

/* .moreModal-tabs-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-height: 293px;
} */

:deep(.el-tabs__item) {
    color: rgba(17, 24, 39, 1) !important;
    font-size: 16px;
    font-weight: bold;
    padding: 0px !important;
}

:deep(.el-tabs__item.is-active) {
    color: rgba(17, 24, 39, 1) !important;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 2px solid rgba(17, 24, 39, 1);
    z-index: 9999;
}

:deep(.el-tabs__active-bar) {
    left: 56px;
    background-color: rgba(223, 228, 237, 1) !important;
}

:deep(.el-tabs__nav) {
    color: rgba(17, 24, 39, 1) !important;
    float: none !important;
    display: flex;
    justify-content: space-around;
}

:deep(.el-tabs__header) {
    margin: 0px 0px 0px !important;
}

.title-content {
    color: rgba(17, 24, 39, 1);
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;
    margin-top: 12px;
}

.title-time {
    color: rgba(149, 150, 157, 1);
    font-size: 12px;
    line-height: 17px;
    margin-top: 12px;
}

.title-content-dot {
    color: rgba(17, 24, 39, 1);
    position: absolute;
    right: -2px;
    top: -18px;
}

.sj-piece {
    width: 16px;
    height: 16px;
    position: absolute;
    left: 4px;
    top: -8px;
}
</style>